import handyScroll from 'handy-scroll'
import 'handy-scroll/dist/handy-scroll.css'

import Vue from 'vue'

const handyScrollDirective = {

  bind(el, binding, vnode, prevVnode) {
    handyScroll.mount(el);
  },
  updated(el, binding, vnode, prevVnode) {
    setTimeout(() => handyScroll.update(el), 500);
  },
  componentUpdated(el, binding, vnode, prevVnode) {
    setTimeout(() => handyScroll.update(el), 500);
  },
  unbind(el, binding, vnode, prevVnode) {
      handyScroll.destroy(el);
  }
}

Vue.directive('handy-scroll', handyScrollDirective)
