<template>
  <button class="wizard-btn" tabindex="-1" type="button">
    <slot></slot>
  </button>
</template>
<script>
  export default {}
</script>
<style>
</style>
